export default function() {
    const PREFIX = '_RAINC_';
    const cartButtonLink = $('.shopping-cart-item');

    // Save the form's URL to local storage.
    const saveFormURL = () => {
        const onShoppingCartForm = $('.shopping-cart-signup-form').length;

        if (onShoppingCartForm) {
            setLocalStorageItem('saved_form_url', window.location.href);
        }
    };

    // Get a value from local storage by it's key.
    const getLocalStorageItem = (key, prefix = PREFIX) => {

        if (typeof Storage !== 'undefined') {
            return localStorage.getItem(prefix + key);
        }

        console.warn('No Web Storage support');
    };

    // Set a value to local storage.
    const setLocalStorageItem = (key, val, prefix = PREFIX) => {
        if (typeof Storage === 'undefined') {
            console.warn('No Web Storage support');
        } else {
            localStorage.setItem(prefix + key, val);
        }
    };


    const maybeShowShoppingCart = () => {
        const currentUrl = window.location.href;
        const formUrl = getLocalStorageItem('saved_form_url');
        const onShoppingCartForm = $('.shopping-cart-signup-form').length;

        if (formUrl !== null) {
            cartButtonLink.attr('href', formUrl);
            setLocalStorageItem('previous_saved_form_url', formUrl);

            if (currentUrl !== formUrl && !onShoppingCartForm) {
                const iconContainer = $('.shopping-cart-icon')
                iconContainer.addClass('show-cart');
            }
        }
    };
    saveFormURL()
    maybeShowShoppingCart()
}


